@import url("./nav.css");
@import url("./main.css");
@import url("./footer.css");

body.grid {
	grid-template-areas: "nav nav nav" ". main ." "footer footer footer";
	grid-template-rows: var(--nav-height, 4rem) auto auto;
	grid-template-columns: 12px 1fr 12px;
	row-gap: 2em;
}

#nav {
	grid-area: nav;
}

#main {
	grid-area: main;
}

#sidebar {
	grid-area: sidebar;
}

#footer {
	grid-area: footer;
}

button.btn[is="share-button"] {
	bottom: 1rem;
	right: 1rem;
	padding: 0.8rem;
	font-size: 1.5rem;
}

@media (max-width: 800px) {
	.mobile-hidden {
		display: none;
	}
}
