@import url("./buttons.css");

:root {
	--main-font: "Roboto";
	--title-font: "Libertine";
	--line-height: 1.3;
	--default-cursor: auto;
	--link-color: currentColor;

	--desktop-rem: 1.3vmax;
	--tablet-rem: 1.6vmax;
	--mobile-rem: 20px;

	--button-border: none;
	--button-border-radius: 0.15rem;
	--button-padding: 0.4rem;

	--dialog-border: none;

	/* Max value = 2^32 - 1 */
	--z-max: 2147483647;

	/* Specify highest allowed `z-index`, other than `<dialog>`, etc. */
	--z-top: 1000;
	color-scheme: light dark;
}

[data-theme="light"] {
	color-scheme: light;
}

[data-theme="dark"] {
	color-scheme: dark;
}
