/*============================List of all available fonts============================*/
@font-face {
	font-family: "Hack";
	font-weight: 400;
	font-style: normal;
	font-display: swap;
	src: url("https://cdn.kernvalley.us/fonts/hack-regular-webfont.eot?#iefix&v=2.013") format("embedded-opentype"), local("hack"), url("https://cdn.kernvalley.us/fonts/hack-regular-webfont.woff2") format("woff2"), url("https://cdn.kernvalley.us/fonts/hack-regular-webfont.woff") format("woff"), url("https://cdn.kernvalley.us/fonts/hack-regular-webfont.ttf") format("truetype"), url("https://cdn.kernvalley.us/fonts/hack-regular-webfont.svg") format("svg");
}

@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local("Roboto"), local("Roboto-Regular"), url("https://cdn.kernvalley.us/fonts/roboto.woff2") format("woff2"), url("https://cdn.kernvalley.us/fonts/roboto.woff") format("woff"), url("https://cdn.kernvalley.us/fonts/roboto.ttf") format("truetype");
}

@font-face {
	font-family: "Rye";
	font-style: normal;
	font-weight: 400;
	src: url("https://cdn.kernvalley.us/fonts/rye.eot"); /* IE9 Compat Modes */
	src: local("Rye"),
	url("https://cdn.kernvalley.us/fonts/rye.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
	url("https://cdn.kernvalley.us/fonts/rye.woff2") format("woff2"), /* Super Modern Browsers */
	url("https://cdn.kernvalley.us/fonts/rye.woff") format("woff"), /* Modern Browsers */
	url("https://cdn.kernvalley.us/fonts/rye.ttf") format("truetype"), /* Safari, Android, iOS */
	url("https://cdn.kernvalley.us/fonts/rye.svg#Rye") format("svg"); /* Legacy iOS */
}

@font-face {
	font-family: "Comfortaa";
	font-weight: 400;
	font-style: normal;
	font-display: swap;
	src: local("Comfortaa"), local("Comfortaa Regular"), url("https://cdn.kernvalley.us/fonts/Comfortaa.woff2") format("woff2"), url("https://cdn.kernvalley.us/fonts/Comfortaa.woff") format("woff"), url("https://cdn.kernvalley.us/fonts/Comfortaa.ttf") format("truetype");
}

@font-face {
	font-family: "Ubuntu";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local("Ubuntu"), url("https://cdn.kernvalley.us/fonts/ubuntu.woff2") format("woff2"), url("https://cdn.kernvalley.us/fonts/ubuntu.woff") format("woff"), url("https://cdn.kernvalley.us/fonts/ubuntu.ttf") format("truetype");
}

@font-face {
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local("Open Sans"), local("OpenSans"), url("https://cdn.kernvalley.us/fonts/OpenSans.woff2") format("woff2"), url("https://cdn.kernvalley.us/fonts/OpenSans.woff") format("woff"), url("https://cdn.kernvalley.us/fonts/OpenSans.ttf") format("truetype");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
	font-family: "Libertine";
	font-weight: 400;
	font-style: normal;
	font-display: swap;
	src: local("Libertine"), url("https://cdn.kernvalley.us/fonts/Libertine.woff") format("woff"), url("https://cdn.kernvalley.us/fonts/Libertine.ttf") format("truetype");
}

@font-face {
	font-family: "Alice";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local("Alice"), local("Alice-Regular"), /*url("https://cdn.kernvalley.us/fonts/Alice.woff2") format("woff2"), */url("https://cdn.kernvalley.us/fonts/Alice.woff") format("woff"), url("https://cdn.kernvalley.us/fonts/Alice.ttf") format("truetype");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: "Acme";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local("Acme"), local("Acme-Regular"), url("https://cdn.kernvalley.us/fonts/acme.woff2") format("woff2"), url("https://cdn.kernvalley.us/fonts/acme.woff") format("woff"), url("https://cdn.kernvalley.us/fonts/acme.ttf") format("truetype");;
}

/**
 * @todo Add TTF format
 */
@font-face {
	font-family: "GNUTypewriter";
	font-weight: 400;
	font-style: normal;
	font-display: swap;
	src: local("GNUTypewriter"), url("https://cdn.kernvalley.us/fonts/gnome-typewriter.woff") format("woff"), url("https://cdn.kernvalley.us/fonts/gnome-typewriter.ttf") format("truetype"), url("https://cdn.kernvalley.us/fonts/gnome-typewriter.otf") format("opentype");
}
@font-face {
	font-family: "PressStart";
	font-weight: 400;
	font-style: normal;
	font-display: swap;
	src: local("Press Start 2P"), url("https://cdn.kernvalley.us/fonts/PressStart2P.woff") format("woff"), url("https://cdn.kernvalley.us/fonts/PressStart2P.ttf") format("truetype");
}

@font-face {
	font-family: "Mister Pixel";
	font-weight: 400;
	font-style: normal;
	font-display: swap;
	src: url("https://cdn.kernvalley.us/fonts/MisterPixel.woff") format("woff"), url("https://cdn.kernvalley.us/fonts/MisterPixel.ttf") format("truetype"), url("https://cdn.kernvalley.us/fonts/MisterPixel.svg") format("svg");
}

@font-face {
	font-family: "Web Symbols";
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: local("WebSymbols-Regular"), url("https://cdn.kernvalley.us/fonts/WebSymbols-Regular.woff") format("woff"), url("https://cdn.kernvalley.us/fonts/WebSymbols-Regular.otf") format("opentype");;
}

@font-face {
	font-family: "Outline";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local("Outline"), url("https://cdn.kernvalley.us/fonts/DouarOutline.woff") format("woff"), url("https://cdn.kernvalley.us/fonts/DouarOutline.ttf") format("truetype");
}

@font-face {
	font-family: "Intuitive";
	font-weight: 400;
	font-style: normal;
	font-display: swap;
	src: local("Intuitive"), url("https://cdn.kernvalley.us/fonts/Intuitive.woff") format("woff"), url("https://cdn.kernvalley.us/fonts/Intuitive.ttf") format("truetype");
}

@font-face {
	font-family: "Droid Sans Mono";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local("Droid Sans Mono"), local("DroidSansMono"), url("https://cdn.kernvalley.us/fonts/DroidSansMono-regular.woff") format("woff"), url("https://cdn.kernvalley.us/fonts/DroidSansMono-regular.woff2") format("woff2"), url("https://cdn.kernvalley.us/fonts/DroidSansMono-regular/ttf") format("truetype");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
	font-family: "HennyPenny";
	font-weight: 400;
	font-style: normal;
	font-display: swap;
	src: local("Henny Penny"), local("HennyPenny-Regular"), url("https://cdn.kernvalley.us/fonts/HennyPenny-Regular.woff") format("woff"), url("https://cdn.kernvalley.us/fonts/HennnyPenny-Regular.ttf") format("truetype");
}
