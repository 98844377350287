@charset "UTF-8";
@import url("./vars.css");
@import url("https://cdn.kernvalley.us/css/normalize/normalize.css");
@import url("https://cdn.kernvalley.us/css/core-css/rem.css");
@import url("https://cdn.kernvalley.us/css/core-css/viewport.css");
@import url("https://cdn.kernvalley.us/css/core-css/element.css");
@import url("https://cdn.kernvalley.us/css/core-css/class-rules.css");
@import url("https://cdn.kernvalley.us/css/core-css/cdn-fonts.css");
@import url("https://cdn.kernvalley.us/css/core-css/forms.css");
@import url("https://cdn.kernvalley.us/css/core-css/scrollbar.css");
@import url("./layout.css");

.btns.flex {
	gap: 12px;
}

.btns .btn {
	max-width: 250px;
}

.flex.space-evenly {
	justify-content: space-evenly;
}

dialog.form-dialog[open] {
	border-radius: 12px;
	max-width: 80%;
	width: 550px;
}

@media (max-width: 600px) {
	dialog.form-dialog[open] {
		max-width: 95%;
	}
}

.btn.btn-outline:disabled {
	color: #dadada;
}

.btn.btn-outline.reset-link {
	margin-top: 1.5em;
	color: #db3a3a;
}

.error-message {
	max-width: 400px;
}

.error-message:empty {
	display: none;
}
